<template>
  <ion-page>
    <HeaderPage :title="event.name" v-if="event" />
    <ion-content :fullscreen="true" v-if="event">
      <img :src="event.cover">
      <div class="container">
          <div class="event-name">{{ event.name }}</div>
          <div class="event-period">
            Periode penukaran {{ $filters.dateFormat(event.start_at) }} -
            {{ $filters.dateFormat(event.finish_at) }}
          </div>
          <div class="divinder"></div>
          <div class="e23p" v-if="event.active">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M7.98625 15.9725C12.3969 15.9725 15.9725 12.3969 15.9725 7.98625C15.9725 3.57557 12.3969 0 7.98625 0C3.57557 0 0 3.57557 0 7.98625C0 12.3969 3.57557 15.9725 7.98625 15.9725Z" fill="#FFBC51"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.98647 1.62635C8.26345 1.62635 8.53633 1.644 8.80399 1.6784C9.04838 1.70975 9.27228 1.53686 9.30369 1.29248C9.3351 1.04809 9.16219 0.824193 8.9178 0.792812C8.61285 0.753608 8.30199 0.733398 7.98647 0.733398C7.74008 0.733398 7.54004 0.933435 7.54004 1.17983C7.54004 1.42637 7.74008 1.62635 7.98647 1.62635Z" fill="#FCD28A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.91554 1.92467C11.4874 2.42507 12.7947 3.52083 13.5727 4.94592C13.6908 5.16233 13.9622 5.24208 14.1785 5.12398C14.3947 5.00588 14.4745 4.73438 14.3564 4.51827C13.4694 2.8932 11.9785 1.64465 10.1865 1.07388C9.9517 0.999095 9.70041 1.129 9.62562 1.36371C9.55087 1.59869 9.68078 1.8498 9.91554 1.92467Z" fill="#FCD28A"/>
              <path d="M7.98587 13.723C11.1542 13.723 13.7227 11.1545 13.7227 7.98611C13.7227 4.81774 11.1542 2.24927 7.98587 2.24927C4.8175 2.24927 2.24902 4.81774 2.24902 7.98611C2.24902 11.1545 4.8175 13.723 7.98587 13.723Z" fill="#FFB32C"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.36477 13.0896C3.5151 12.1371 2.24902 10.2082 2.24902 7.98605C2.24902 4.81998 4.81963 2.24927 7.98588 2.24927C9.3917 2.24927 10.6801 2.75605 11.6784 3.59712C10.8927 3.19212 10.0015 2.96355 9.05731 2.96355C5.89106 2.96355 3.32045 5.53427 3.32045 8.70034C3.32045 10.4611 4.11513 12.0371 5.36477 13.0896Z" fill="#F49E17"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.54305 4.76439C7.62309 4.59182 7.79593 4.48145 7.98606 4.48145C8.17616 4.48145 8.349 4.59182 8.42905 4.76439L9.25908 6.55236L11.2162 6.78932C11.4049 6.81208 11.5633 6.9423 11.622 7.12334C11.6807 7.30415 11.6292 7.50262 11.49 7.63187L10.0459 8.974L10.4254 10.9084C10.462 11.095 10.387 11.2858 10.2332 11.3976C10.0794 11.5094 9.87472 11.5217 9.70858 11.429L7.98606 10.4706L6.26351 11.429C6.0974 11.5217 5.89273 11.5094 5.73891 11.3976C5.58511 11.2858 5.51013 11.095 5.54673 10.9084L5.92623 8.974L4.48213 7.63187C4.34285 7.50262 4.29135 7.30415 4.35009 7.12334C4.40886 6.9423 4.56718 6.81208 4.75592 6.78932L6.71301 6.55236L7.54305 4.76439Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.4412 6.7063C6.18163 8.77166 8.07409 10.2906 10.3427 10.487L10.4254 10.9084C10.462 11.0949 10.387 11.2856 10.2332 11.3974C10.0794 11.5092 9.8747 11.5217 9.70859 11.4292L7.98605 10.4702L6.26352 11.4292C6.09737 11.5217 5.89273 11.5092 5.73891 11.3974C5.58509 11.2856 5.51012 11.0949 5.54673 10.9084L5.92623 8.9738L4.48213 7.63201C4.34288 7.50237 4.29134 7.30416 4.35009 7.12309C4.40884 6.94237 4.5672 6.81202 4.75591 6.78916L5.4412 6.7063Z" fill="#E9F2FE"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="16" height="16" fill="white"/>
              </clipPath>
              </defs>
            </svg>
            {{ event.coupon_price }} poin =
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.74411V4.19125C0 3.63726 0.449657 3.1875 1.00381 3.1875H14.9801C15.5341 3.1875 15.9839 3.63726 15.9839 4.19125V6.74411C15.1777 6.74515 14.5235 7.39994 14.5235 8.20629C14.5235 9.01265 15.1777 9.66741 15.9839 9.66846V12.2213C15.9839 12.7753 15.5341 13.2251 14.9801 13.2251H1.00381C0.449657 13.2251 0 12.7753 0 12.2213V9.66846L0.00142399 9.66848C0.80833 9.66848 1.46363 9.01329 1.46363 8.20629C1.46363 7.3993 0.80833 6.74411 0.00142399 6.74411H0Z" fill="#007CCD"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.31931V3.76645C0 3.21246 0.449657 2.7627 1.00381 2.7627H14.9801C15.5341 2.7627 15.9839 3.21246 15.9839 3.76645V6.31931C15.1777 6.32035 14.5235 6.97514 14.5235 7.78149C14.5235 8.58784 15.1777 9.24261 15.9839 9.24365V11.7965C15.9839 12.3505 15.5341 12.8003 14.9801 12.8003H1.00381C0.449657 12.8003 0 12.3505 0 11.7965V9.24365L0.00142399 9.24367C0.80833 9.24367 1.46363 8.58848 1.46363 7.78149C1.46363 6.9745 0.80833 6.31931 0.00142399 6.31931H0Z" fill="#0094DD"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34329 2.7627H14.9804C15.5344 2.7627 15.9842 3.21246 15.9842 3.76645V6.31931C15.178 6.32035 14.5238 6.97514 14.5238 7.78149C14.5238 8.58784 15.178 9.24261 15.9842 9.24365V11.7965C15.9842 12.3505 15.5344 12.8003 14.9804 12.8003H9.7391C6.46473 11.5406 4.13867 8.3642 4.13867 4.64883C4.13867 4.00124 4.20943 3.37004 4.34329 2.7627Z" fill="#47BDFF"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.72834 5.4115C7.77597 5.30883 7.8788 5.24316 7.99192 5.24316C8.10502 5.24316 8.20785 5.30883 8.25547 5.4115L8.74931 6.47526L9.9137 6.61624C10.026 6.62978 10.1202 6.70726 10.1551 6.81497C10.1901 6.92254 10.1594 7.04062 10.0766 7.11752L9.21741 7.91603L9.44321 9.06691C9.46498 9.17794 9.42037 9.29141 9.32885 9.35794C9.23735 9.42447 9.11558 9.43182 9.01674 9.37666L7.99192 8.80641L6.96708 9.37666C6.86825 9.43182 6.74648 9.42447 6.65497 9.35794C6.56347 9.29141 6.51885 9.17794 6.54063 9.06691L6.76641 7.91603L5.90724 7.11752C5.82438 7.04062 5.79373 6.92254 5.82868 6.81497C5.86365 6.70726 5.95784 6.62978 6.07014 6.61624L7.23451 6.47526L7.72834 5.4115Z" fill="white"/>
            </svg>
            1 kupon
          </div>
          <div class="description mt-1 bb mb-1">
            <markdown :source="event.description"></markdown>
          </div>
          <div class="winner bb" v-if="event.winners">
            <div class="event-name">Pemenang Undian</div>
            <ul class="item-list">
              <li v-for="w in event.winners" :key="w.id" class="winitem flex justify-content-between">
                <div class="number">{{ w.order }}</div>
                <div class="name">{{ w.user.name }}</div>
                <div class="ext">***-{{ $filters.maskCoupon(w.coupon_number) }}</div>
              </li>
            </ul>
          </div>
          <div class="testimoni mb-1" v-if="event.testimoni">
            <div class="event-name mb-1 mt-1">Testimoni</div>
            <div class="df-testimoni" v-for="testi in event.testimoni" :key="testi.id">
              <div class="df-date text-sm">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.00004 0.333344L9.06004 4.50668L13.6667 5.18001L10.3334 8.42668L11.12 13.0133L7.00004 10.8467L2.88004 13.0133L3.66671 8.42668L0.333374 5.18001L4.94004 4.50668L7.00004 0.333344Z" fill="#F2C94C"/>
                </svg>
                {{ $filters.dateFormat(testi.created_at, 'full') }}
              </div>
              <div class="name text-bold">{{ testi.user.name }}</div>
              <p>{{ testi.content }}</p>
              <div class="df-img" v-if="testi.images">
                <img v-for="(img, i) in testi.images" :key="i" :src="img.url" class="thumb">
              </div>
            </div>
          </div>
      </div>
    </ion-content>
    <ion-footer class="ion-no-border container" v-if="event && event.active == 1 && event.use_coupon">
      <ion-toolbar>
        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn mt-10" @click="buy" >Beli Kupon</ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonFooter, IonToolbar, IonButton, modalController} from '@ionic/vue'
import HeaderPage from '@/components/headerPage'
import ModalBuy from '@/components/buy'
import Markdown from 'vue3-markdown-it'

export default {
  components: {
    Markdown,
    HeaderPage,
    IonContent,
    IonPage,
    IonFooter, IonToolbar, IonButton
  },
  created(){
    this.loadEvent()
  },
  data () {
    return {
      event: null
    }
  },
  watch: {
    '$route': 'loadEvent'
  },
  methods: {
    async loadEvent() {
      let id = this.$route.params.id
      if(id == undefined)
        return;

      let res = await this.$api.get(`/api/event/${id}`)
      this.event = res.data
      document.title = 'doo.id - ' + this.event.name
    },
    async buy () {
      let buy = await modalController
        .create({
          component: ModalBuy,
          swipeToClose: true,
          cssClass: 'half-modal',
          componentProps: {
            event: this.event
          }
        })
      return buy.present()
    }
  }
}
</script>

<style lang="scss">
.event-name {
  font-weight: bold;
}
.event-period {
  font-size: 14px;
  color: #545454;
}
.e23p {
  display: flex;
  // align-items: center;
  // justify-content: center;
  svg {
    margin-right: 5px;
    margin-left: 5px;
  }
}

.winner {
  h4 {
    font-size: 16px;
  }
  .coupon-code {
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0;
  }
  .email-winner {
    color: #545454;
  }
}
.description {
  text-align: left;
}
ul.item-list {
  list-style: none;
    padding-left: 0px;
    text-align: left;
    .number {
      width: 24px;
      background: #219653;
      color: #fff;
      border-radius: 50%;
      height: 24px;
      text-align: center;
      margin-right: 10px;
    }
    .name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .winitem {
      height: 34px;
    }
    .ext {
      color: #545454;
    }
}
</style>
