<template>
<ion-content class="ion-padding" :fullscreen="true">
  <div class="container">
    <h3>Konfirmasi Pembelian Kupon</h3>
    <div class="row">
      <div class="col">
        Doo.id Poin
      </div>
      <div class="col">
        {{ $filters.numFormat($auth.point) }}
      </div>
    </div>
    <div class="row">
      <div class="col">
        1x Kupon
      </div>
      <div class="col">{{ event.coupon_price }}</div>
    </div>
    <div class="divinder"></div>
    <div class="row">
      <div class="col">
        Sisa Poin
      </div>
      <div class="col">
        {{ calc() }}
      </div>
    </div>
  </div>
</ion-content>
<ion-footer class="ion-no-border container split">
  <ion-toolbar>
    <div class="flex justify-content-between">
      <ion-button mode="ios" shape="round" expand="full" color="transparent" class="btn transparent" @click="close">Batal</ion-button>
      <ion-button mode="ios" shape="round" expand="full" color="dark" class="btn"  @click="buy" :disabled="disabled">Konfirmasi</ion-button>
    </div>
  </ion-toolbar>
</ion-footer>
</template>

<script>
import { IonContent, IonFooter, IonToolbar, IonButton, modalController, toastController  } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    IonContent, IonFooter, IonToolbar, IonButton
  },
  props: {
    event: Object
  },
  data () {
    return {
      disabled: false
    }
  },
  methods: {
    async close() {
      await modalController.dismiss()
    },
    async buy () {
      let res = await this.$api.post('/api/coupon/buy', {event_id: this.event.id})
      if(res.error) {
        let toast = await toastController.create({
            message: res.message,
            color: 'danger',
            duration: 2000,
            mode: 'md'
          })
        return toast.present();
      }else{
        modalController.dismiss()
        this.$store.dispatch('auth/fetchUser')
        this.$router.push('/coupon')
      }
    },
    calc() {
      let div = this.$auth.point - this.event.coupon_price
      if(div < 0) {
        this.disabled = true
      }
      return div;
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
}

.btn {
  width: 100%;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &.transparent {
    box-shadow: none;
    color: #000;
    &.button-round {
      --box-shadow: none !important;
    }
  }
}
</style>
